<template>
    <LoadingComponent v-if="loading" />
    <div v-else class="dosiin_home-events dosiin_home home-page main-page">
        <div class="mb-event" :class="{ 'section-event-disable' : eventPage.status_event == 0 }" v-for="(eventPage , index ) in events" :key="index">
            <div class="section-event">                
                <a :href="eventPage.status_event == 1 ? eventPage.event_link  : 'javascript:;'" :title="eventPage.page">
                    <div class="home-item-event">       
                        <Banner :pairsObject="eventPage.main_pair" />                      
                        <div class="end-event center" v-if="eventPage.status_event == 0">
                            <div class="color-white"><b>Sự kiện kết thúc </b></div>
                            <div class="date-time color-white" v-text="_momentDateMonthYear(eventPage.avail_till_timestamp)"></div>
                        </div>
                    </div>
                    <div class="info-event">
                        <h3 class="title-event" v-text="eventPage.page"></h3>
                    </div>
                </a>
                
                <vue-countdown :time=" eventPage.avail_till_timestamp * 1000 - currentTime > 0 ? eventPage.avail_till_timestamp * 1000 - currentTime : 0 " v-slot="{ days, hours, minutes, seconds }">
                    <ul class="dosiin_countdown hotdeal-countdown">
                        <li class="dosiin_countdown_days">
                            <div class="number" v-text="_addLeadingZero(days)"></div>
                            <span>Ngày</span>
                        </li>
                        <li class="dosiin_countdown_hours">
                            <div class="number" v-text="_addLeadingZero(hours)"></div>
                            <span>Giờ</span>
                        </li>
                        <li class="dosiin_countdown_minutes">
                            <div class="number" v-text="_addLeadingZero(minutes)"></div>
                            <span>Phút</span>
                        </li>
                        <li class="dosiin_countdown_seconds">
                            <div class="number" v-text="_addLeadingZero(seconds)"></div>
                            <span>Giây</span>
                        </li>
                    </ul>
                </vue-countdown>
                
                
                <div class="d-flex align-items-center" v-if="eventPage.event_id != 0">
                    <ul class="home-new-img-brand" style="padding-top: 0;">
                        <template  v-for="(company , index ) in eventPage.event_data[0].companies" :key="index">
                            <li class="group-img-circle brand_img-1" v-if="index < 5">
                                <BrandLogo 
                                    v-if="index < 5"
                                    :width="40"
                                    :height="40"
                                    :brand="company"
                                    :imageClass="'user-avatar img-circle'"
                                />                        
                            </li>
                        </template>
                    </ul>
                    <span class="more-brand" v-if="eventPage.event_data[0].companies.length > 5"> 
                        <b>+ {{ eventPage.event_data[0].companies_count - 5 }}</b> 
                        thương hiệu
                    </span>                                    
                </div>

                <div class="brand-description_wrapper brand-item" v-else-if="eventPage.company_id != 0">
                    <div class="brand-description_img brand-item_img-wrapper">
                        <BrandLogo 
                            :brand="eventPage.company"
                        />
                    </div>
                    <div class="brand-description_info">
                        <a :href="`/?dispatch=companies.view&company_id=${eventPage.company_id}`" :title="eventPage.company.company">
                            <h5 class="brand-item_title" v-text="eventPage.company.company"></h5>
                        </a>
                        <dl class="brand-item_social">
                            <dd>
                                <span class="dosiin_brand-follow-number" v-text="eventPage.company.follow_count">
                                </span> người theo dõi
                            </dd>
                        </dl>
                    </div>
                    <div class="brand-description_follow">
                        <FollowButton 
                            @update:modelValue="newValue => eventPage.company.follow_count = newValue"
                            class="follow-with-text-btn"
                            followable_type="brand" 
                            :followable_id="eventPage.company.company_id" 
                            :is_follow="eventPage.company.is_follow"
                        />
                    </div>
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
    import { inject } from 'vue';
    import FollowButton from '@/components/buttons/FollowButton';
    export default {
        components:{
            FollowButton
        },
        setup() {
            const loading = inject('loading');
            const events = inject('events');
            const params = inject('params');
            const currentTime = inject('currentTime');

            return {
                loading,
                events,
                params,
                currentTime
            }
        },
    }
</script>