<template>
    <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
        <LoadingComponent v-if="loading" />
        <div v-else class="dosiin-event-page-pc">
            <template v-for="(eventPage , index ) in events" :key="index">
                <section class="page-event">
                    <div class="dosiin-event-page" :class="eventPage.status_event == 0 ? 'event-disable' : ''">
                        <div class="event-info-page">
                            <span class="title-event" v-text="eventPage.page"></span>
                            <vue-countdown 
                                :time=" eventPage.avail_till_timestamp * 1000 - currentTime > 0 ? eventPage.avail_till_timestamp * 1000 - currentTime : 0 " 
                                v-slot="{ days, hours, minutes, seconds }"
                            >
                                <div class="time-count-down">
                                    <ul class="dosiin_countdown hotdeal-countdown">
                                        <li class="dosiin_countdown_days">
                                            <div class="number" v-text="_addLeadingZero(days)"></div>
                                            <span>Ngày</span>
                                        </li>
                                        <li class="dosiin_countdown_hours">
                                            <div class="number" v-text="_addLeadingZero(hours)"></div>
                                            <span>Giờ</span>
                                        </li>
                                        <li class="dosiin_countdown_minutes">
                                            <div class="number" v-text="_addLeadingZero(minutes)"></div>
                                            <span>Phút</span>
                                        </li>
                                        <li class="dosiin_countdown_seconds">
                                            <div class="number" v-text="_addLeadingZero(seconds)"></div>
                                            <span>Giây </span>
                                        </li>
                                    </ul>
                                </div>
                            </vue-countdown>

                            <div class="line-time-count-event"></div>
                            <template v-if="eventPage.event_id != 0">
                                <ul class="info-brand-event">
                                    <template  v-for="(company , index ) in eventPage.event_data[0].companies" :key="index">
                                        <li class="info-brand-event-detail" v-if="index < 3">
                                            <div class="avatar-brand">
                                                <BrandLogo 
                                                    v-if="index < 3"
                                                    :width="40"
                                                    :height="40"
                                                    :brand="company"
                                                    imageClass="brand-top-selling-item_img"
                                                />
                                            </div>
                                            <div class="info-brand">
                                                <a :href="`/?dispatch=companies.view&company_id=${company.company_id}`" :title="company.company">
                                                    <span v-text="company.company"></span>
                                                </a>
                                            </div>
                                        </li>
                                    </template>
                                </ul>

                                <div class="title-see-more-brand" v-if="eventPage.event_data[0].companies.length > 3">
                                    <div class="number-see-more">+ {{ eventPage.event_data[0].companies_count - 3 }}</div>
                                    <div class="see-more-brand">thương hiệu tham gia</div>
                                </div>
                            </template>

                            <template v-else-if="eventPage.company_id != 0">
                                <div class="brand-description_wrapper">
                                    <div class="brand-description_img brand-item_img-wrapper">
                                        <BrandLogo 
                                            :width="62"
                                            :height="62"
                                            imageClass="brand-top-selling-item_img"
                                            :brand="eventPage.company"
                                        />
                                        <div class="brand-description_info">
                                            <a :href="`/?dispatch=companies.view&company_id=${eventPage.company_id}`" :title="eventPage.company.company">
                                                <h5 class="brand-item_title" v-text="eventPage.company.company"></h5>
                                            </a>
                                            <dl class="brand-item_social">
                                                <dd class="follow-users">
                                                    <span class="number-user" v-text="eventPage.company.follow_count"> </span>
                                                    <span class="user">người theo dõi</span>
                                                </dd>
                                                <dd class="follow-users">
                                                    <span class="number-user">{{eventPage.company.product_count}}</span>
                                                    <span class="user">sản phẩm</span>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                    <div class="brand-description_follow">
                                        <FollowButton 
                                            @update:modelValue="newValue => eventPage.company.follow_count = newValue"
                                            class="follow-with-text-btn"
                                            followable_type="brand" 
                                            :followable_id="eventPage.company.company_id" 
                                            :is_follow="eventPage.company.is_follow"
                                        />
                                    </div>
                                </div>
                                <div class="brand-description-detail">
                                    <span v-html="eventPage.company.company_description"></span>
                                </div>
                            </template>

                            <div class="seemore-btn" :class="{ 'disable' : eventPage.status_event == 0}">
                                <a  v-if="eventPage.status_event == 1" class="primary-button button" :title="eventPage.page" :href="eventPage.event_link">
                                    <span class="primary-link_text">Xem thêm</span>
                                    <i class="dsi dsi-act-arrow-forward"></i>
                                </a>
                                <a v-else class="primary-button button" :title="eventPage.page" href="javascript:;" >
                                    <span class="primary-link_text">Đã hết hạn</span>
                                </a>
                                <!-- <a class="primary-button button" :href="eventPage.status_event == 1 ? eventPage.event_link  : 'javascript:;'" :title="eventPage.page" >
                                    
                                    <span class="primary-link_text">Xem thêm</span>
                                    <i class="dsi dsi-act-arrow-forward"></i>
                                </a> -->
                            </div>
                        </div>
                        <div class="event-right-page">
                            <div class="event-item-page">
                                <a :href="eventPage.status_event == 1 ? eventPage.event_link  : 'javascript:;'" :title="eventPage.page">
                                    <Banner :pairsObject="eventPage.main_pair" wrapperClass="img-dosiin-event" />
                                    <div class="title-event-disable" v-if="eventPage.status_event == 0">
                                        <div class="title-disable">Sự kiện kết thúc</div>
                                        <div class="date-disable" v-text="_momentDateMonthYear(eventPage.avail_till_timestamp)"></div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
        </div>
    </div>
</template>

<script>
    import { inject } from 'vue';
    export default {
        setup() {
            const loading = inject('loading');
            const events = inject('events');
            const params = inject('params');
            const currentTime = inject('currentTime');

            return {
                loading,
                events,
                params,
                currentTime
            }
        },
    }
</script>

<style>
    .dosiin-event-page .event-right-page .img-dosiin-event img {
        min-height: 390px;
    }
</style>