<template>
    <EventsMB v-if="layout == 4"/>
    <EventsPC v-else/>
</template>

<script>
    import EventsMB from '@/pages/mb/home/Events';
    import EventsPC from '@/pages/pc/home/Events';
    import EventService from '@/services/event';
    import { serialize } from '@/utlis/serialize';

    import { ref , provide } from 'vue';
    export default {
        name : 'events',
        components: {
            EventsMB,
            EventsPC
        },
        setup(){
            const loading = ref(true);
            const events  = ref([]);
            const params  = ref({});
            const currentTime = new Date().getTime()
            getEvents();

            async function getEvents (){
                const response = await EventService.fetchEvents(serialize({'page_type' : 'L' , 'status' : 'A' , 'sort_by' : 'id' , 'sort_order' : 'desc'}));

                if(response.data){
                    events.value = response.data.events;
                    params.value = response.data.params;
                    loading.value = false;
                }
            }

            provide('loading', loading);
            provide('events', events);
            provide('params', params);
            provide('currentTime',currentTime);
        }
    }
    
</script>
